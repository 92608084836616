<script>import mixpanel from "mixpanel-browser";
import { onMount } from "svelte";
export let token;
export let session;
export let debug;
if (token)
    onMount(() => {
        mixpanel.init(token, { debug });
    });
$: if (token && $session.state === "success")
    mixpanel.identify(`${$session.data.id}`);
</script>
