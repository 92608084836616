<script lang="ts">
  import type { EdgeClientConfig } from "@fxwrapr/web-common/context";
  import { Analytics } from "@fxwrapr/web-common";
  import { session } from "$lib/stores";

  export let edge: EdgeClientConfig;
</script>

<Analytics
  {edge}
  {session}
  hotjarId={import.meta.env.PUBLIC_HOTJAR_ID}
  mixpanelToken={import.meta.env.PUBLIC_MIXPANEL_TOKEN}
  debug={import.meta.env.DEV}
/>
